@charset "UTF-8";
/*
 *  Fonts
 */
/*
 *  Colors
 */
/*
 * Animation Effect
 */
@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 300;
  src: url("../font/nexa/Nexa-Light.woff2") format("woff2"), url("../font/nexa/Nexa-Light.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 400;
  src: url("../font/nexa/Nexa-Regular.woff2") format("woff2"), url("../font/nexa/Nexa-Regular.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: "normal";
  src: url("../font/nexa/Nexa-Book.woff2") format("woff2"), url("../font/nexa/Nexa-Book.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 500;
  src: url("../font/nexa/Nexa-Bold.woff2") format("woff2"), url("../font/nexa/Nexa-Bold.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 600;
  src: url("../font/nexa/Nexa-XBold.woff2") format("woff2"), url("../font/nexa/Nexa-XBold.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 700;
  src: url("../font/nexa/Nexa-Black.woff2") format("woff2"), url("../font/nexa/Nexa-Black.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: 'sigma';
  src: url("../font/icon/sigma.eot?wczgc2");
  src: url("../font/icon/sigma.eot?wczgc2#iefix") format("embedded-opentype"), url("../font/icon/sigma.woff?wczgc2") format("woff"), url("../font/icon/sigma.svg?wczgc2#sigma") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sigma' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-sigma-share:before {
  content: '\eb03'; }

.icon-sigma-play-line:before {
  content: '\eafb'; }

.icon-sigma-mail:before {
  content: '\eaf7'; }

.icon-sigma-arrow-detail:before {
  content: '\e906'; }

.icon-sigma-arrow-down:before {
  content: '\e908'; }

.icon-sigma-arrow-right:before {
  content: '\e909'; }

.icon-sigma-download:before {
  content: '\e90b'; }

.icon-sigma-next:before {
  content: '\eaf9'; }

.icon-sigma-pdf:before {
  content: '\eafa'; }

.icon-sigma-play:before {
  content: '\eafc'; }

.icon-sigma-prev:before {
  content: '\eafd'; }

.icon-sigma-search:before {
  content: '\eafe'; }

.icon-sigma-select-arrow-line:before {
  content: '\eaff'; }

.icon-sigma-select-arrow:before {
  content: '\eb00'; }

.icon-sigma-logo:before {
  content: '\eb02'; }

.icon-sigma-arrow-play:before {
  content: '\eaf8'; }

.icon-sigma-pinterest:before {
  content: '\e901'; }

.icon-sigma-plus:before {
  content: '\e907'; }

.icon-sigma-back:before {
  content: '\e900'; }

.icon-sigma-circle-play:before {
  content: '\e902'; }

.icon-sigma-close:before {
  content: '\e903'; }

.icon-sigma-select-down2:before {
  content: '\e904'; }

.icon-sigma-map-maker:before {
  content: '\e905'; }

.icon-uzman-select-arrow:before {
  content: '\e90a'; }

.icon-home:before {
  content: '\e90c'; }

.icon-home2:before {
  content: '\e90d'; }

.icon-home3:before {
  content: '\e90e'; }

.icon-office:before {
  content: '\e90f'; }

.icon-newspaper:before {
  content: '\e910'; }

.icon-pencil:before {
  content: '\e911'; }

.icon-pencil2:before {
  content: '\e912'; }

.icon-quill:before {
  content: '\e913'; }

.icon-pen:before {
  content: '\e914'; }

.icon-blog:before {
  content: '\e915'; }

.icon-eyedropper:before {
  content: '\e916'; }

.icon-droplet:before {
  content: '\e917'; }

.icon-paint-format:before {
  content: '\e918'; }

.icon-image:before {
  content: '\e919'; }

.icon-images:before {
  content: '\e91a'; }

.icon-camera:before {
  content: '\e91b'; }

.icon-headphones:before {
  content: '\e91c'; }

.icon-music:before {
  content: '\e91d'; }

.icon-play:before {
  content: '\e91e'; }

.icon-film:before {
  content: '\e91f'; }

.icon-video-camera:before {
  content: '\e920'; }

.icon-dice:before {
  content: '\e921'; }

.icon-pacman:before {
  content: '\e922'; }

.icon-spades:before {
  content: '\e923'; }

.icon-clubs:before {
  content: '\e924'; }

.icon-diamonds:before {
  content: '\e925'; }

.icon-bullhorn:before {
  content: '\e926'; }

.icon-connection:before {
  content: '\e927'; }

.icon-podcast:before {
  content: '\e928'; }

.icon-feed:before {
  content: '\e929'; }

.icon-mic:before {
  content: '\e92a'; }

.icon-book:before {
  content: '\e92b'; }

.icon-books:before {
  content: '\e92c'; }

.icon-library:before {
  content: '\e92d'; }

.icon-file-text:before {
  content: '\e92e'; }

.icon-profile:before {
  content: '\e92f'; }

.icon-file-empty:before {
  content: '\e930'; }

.icon-files-empty:before {
  content: '\e931'; }

.icon-file-text2:before {
  content: '\e932'; }

.icon-file-picture:before {
  content: '\e933'; }

.icon-file-music:before {
  content: '\e934'; }

.icon-file-play:before {
  content: '\e935'; }

.icon-file-video:before {
  content: '\e936'; }

.icon-file-zip:before {
  content: '\e937'; }

.icon-copy:before {
  content: '\e938'; }

.icon-paste:before {
  content: '\e939'; }

.icon-stack:before {
  content: '\e93a'; }

.icon-folder:before {
  content: '\e93b'; }

.icon-folder-open:before {
  content: '\e93c'; }

.icon-folder-plus:before {
  content: '\e93d'; }

.icon-folder-minus:before {
  content: '\e93e'; }

.icon-folder-download:before {
  content: '\e93f'; }

.icon-folder-upload:before {
  content: '\e940'; }

.icon-price-tag:before {
  content: '\e941'; }

.icon-price-tags:before {
  content: '\e942'; }

.icon-barcode:before {
  content: '\e943'; }

.icon-qrcode:before {
  content: '\e944'; }

.icon-ticket:before {
  content: '\e945'; }

.icon-cart:before {
  content: '\e946'; }

.icon-coin-dollar:before {
  content: '\e947'; }

.icon-coin-euro:before {
  content: '\e948'; }

.icon-coin-pound:before {
  content: '\e949'; }

.icon-coin-yen:before {
  content: '\e94a'; }

.icon-credit-card:before {
  content: '\e94b'; }

.icon-calculator:before {
  content: '\e94c'; }

.icon-lifebuoy:before {
  content: '\e94d'; }

.icon-phone:before {
  content: '\e94e'; }

.icon-phone-hang-up:before {
  content: '\e94f'; }

.icon-address-book:before {
  content: '\e950'; }

.icon-envelop:before {
  content: '\e951'; }

.icon-pushpin:before {
  content: '\e952'; }

.icon-location:before {
  content: '\e953'; }

.icon-location2:before {
  content: '\e954'; }

.icon-compass:before {
  content: '\e955'; }

.icon-compass2:before {
  content: '\e956'; }

.icon-map:before {
  content: '\e957'; }

.icon-map2:before {
  content: '\e958'; }

.icon-history:before {
  content: '\e959'; }

.icon-clock:before {
  content: '\e95a'; }

.icon-clock2:before {
  content: '\e95b'; }

.icon-alarm:before {
  content: '\e95c'; }

.icon-bell:before {
  content: '\e95d'; }

.icon-stopwatch:before {
  content: '\e95e'; }

.icon-calendar:before {
  content: '\e95f'; }

.icon-printer:before {
  content: '\e960'; }

.icon-keyboard:before {
  content: '\e961'; }

.icon-display:before {
  content: '\e962'; }

.icon-laptop:before {
  content: '\e963'; }

.icon-mobile:before {
  content: '\e964'; }

.icon-mobile2:before {
  content: '\e965'; }

.icon-tablet:before {
  content: '\e966'; }

.icon-tv:before {
  content: '\e967'; }

.icon-drawer:before {
  content: '\e968'; }

.icon-drawer2:before {
  content: '\e969'; }

.icon-box-add:before {
  content: '\e96a'; }

.icon-box-remove:before {
  content: '\e96b'; }

.icon-download:before {
  content: '\e96c'; }

.icon-upload:before {
  content: '\e96d'; }

.icon-floppy-disk:before {
  content: '\e96e'; }

.icon-drive:before {
  content: '\e96f'; }

.icon-database:before {
  content: '\e970'; }

.icon-undo:before {
  content: '\e971'; }

.icon-redo:before {
  content: '\e972'; }

.icon-undo2:before {
  content: '\e973'; }

.icon-redo2:before {
  content: '\e974'; }

.icon-forward:before {
  content: '\e975'; }

.icon-reply:before {
  content: '\e976'; }

.icon-bubble:before {
  content: '\e977'; }

.icon-bubbles:before {
  content: '\e978'; }

.icon-bubbles2:before {
  content: '\e979'; }

.icon-bubble2:before {
  content: '\e97a'; }

.icon-bubbles3:before {
  content: '\e97b'; }

.icon-bubbles4:before {
  content: '\e97c'; }

.icon-user:before {
  content: '\e97d'; }

.icon-users:before {
  content: '\e97e'; }

.icon-user-plus:before {
  content: '\e97f'; }

.icon-user-minus:before {
  content: '\e980'; }

.icon-user-check:before {
  content: '\e981'; }

.icon-user-tie:before {
  content: '\e982'; }

.icon-quotes-left:before {
  content: '\e983'; }

.icon-quotes-right:before {
  content: '\e984'; }

.icon-hour-glass:before {
  content: '\e985'; }

.icon-spinner:before {
  content: '\e986'; }

.icon-spinner2:before {
  content: '\e987'; }

.icon-spinner3:before {
  content: '\e988'; }

.icon-spinner4:before {
  content: '\e989'; }

.icon-spinner5:before {
  content: '\e98a'; }

.icon-spinner6:before {
  content: '\e98b'; }

.icon-spinner7:before {
  content: '\e98c'; }

.icon-spinner8:before {
  content: '\e98d'; }

.icon-spinner9:before {
  content: '\e98e'; }

.icon-spinner10:before {
  content: '\e98f'; }

.icon-spinner11:before {
  content: '\e990'; }

.icon-binoculars:before {
  content: '\e991'; }

.icon-search:before {
  content: '\e992'; }

.icon-zoom-in:before {
  content: '\e993'; }

.icon-zoom-out:before {
  content: '\e994'; }

.icon-enlarge:before {
  content: '\e995'; }

.icon-shrink:before {
  content: '\e996'; }

.icon-enlarge2:before {
  content: '\e997'; }

.icon-shrink2:before {
  content: '\e998'; }

.icon-key:before {
  content: '\e999'; }

.icon-key2:before {
  content: '\e99a'; }

.icon-lock:before {
  content: '\e99b'; }

.icon-unlocked:before {
  content: '\e99c'; }

.icon-wrench:before {
  content: '\e99d'; }

.icon-equalizer:before {
  content: '\e99e'; }

.icon-equalizer2:before {
  content: '\e99f'; }

.icon-cog:before {
  content: '\e9a0'; }

.icon-cogs:before {
  content: '\e9a1'; }

.icon-hammer:before {
  content: '\e9a2'; }

.icon-magic-wand:before {
  content: '\e9a3'; }

.icon-aid-kit:before {
  content: '\e9a4'; }

.icon-bug:before {
  content: '\e9a5'; }

.icon-pie-chart:before {
  content: '\e9a6'; }

.icon-stats-dots:before {
  content: '\e9a7'; }

.icon-stats-bars:before {
  content: '\e9a8'; }

.icon-stats-bars2:before {
  content: '\e9a9'; }

.icon-trophy:before {
  content: '\e9aa'; }

.icon-gift:before {
  content: '\e9ab'; }

.icon-glass:before {
  content: '\e9ac'; }

.icon-glass2:before {
  content: '\e9ad'; }

.icon-mug:before {
  content: '\e9ae'; }

.icon-spoon-knife:before {
  content: '\e9af'; }

.icon-leaf:before {
  content: '\e9b0'; }

.icon-rocket:before {
  content: '\e9b1'; }

.icon-meter:before {
  content: '\e9b2'; }

.icon-meter2:before {
  content: '\e9b3'; }

.icon-hammer2:before {
  content: '\e9b4'; }

.icon-fire:before {
  content: '\e9b5'; }

.icon-lab:before {
  content: '\e9b6'; }

.icon-magnet:before {
  content: '\e9b7'; }

.icon-bin:before {
  content: '\e9b8'; }

.icon-bin2:before {
  content: '\e9b9'; }

.icon-briefcase:before {
  content: '\e9ba'; }

.icon-airplane:before {
  content: '\e9bb'; }

.icon-truck:before {
  content: '\e9bc'; }

.icon-road:before {
  content: '\e9bd'; }

.icon-accessibility:before {
  content: '\e9be'; }

.icon-target:before {
  content: '\e9bf'; }

.icon-shield:before {
  content: '\e9c0'; }

.icon-power:before {
  content: '\e9c1'; }

.icon-switch:before {
  content: '\e9c2'; }

.icon-power-cord:before {
  content: '\e9c3'; }

.icon-clipboard:before {
  content: '\e9c4'; }

.icon-list-numbered:before {
  content: '\e9c5'; }

.icon-list:before {
  content: '\e9c6'; }

.icon-list2:before {
  content: '\e9c7'; }

.icon-tree:before {
  content: '\e9c8'; }

.icon-menu:before {
  content: '\e9c9'; }

.icon-menu2:before {
  content: '\e9ca'; }

.icon-menu3:before {
  content: '\e9cb'; }

.icon-menu4:before {
  content: '\e9cc'; }

.icon-cloud:before {
  content: '\e9cd'; }

.icon-cloud-download:before {
  content: '\e9ce'; }

.icon-cloud-upload:before {
  content: '\e9cf'; }

.icon-cloud-check:before {
  content: '\e9d0'; }

.icon-download2:before {
  content: '\e9d1'; }

.icon-upload2:before {
  content: '\e9d2'; }

.icon-download3:before {
  content: '\e9d3'; }

.icon-upload3:before {
  content: '\e9d4'; }

.icon-sphere:before {
  content: '\e9d5'; }

.icon-earth:before {
  content: '\e9d6'; }

.icon-link:before {
  content: '\e9d7'; }

.icon-flag:before {
  content: '\e9d8'; }

.icon-attachment:before {
  content: '\e9d9'; }

.icon-eye:before {
  content: '\e9da'; }

.icon-eye-plus:before {
  content: '\e9db'; }

.icon-eye-minus:before {
  content: '\e9dc'; }

.icon-eye-blocked:before {
  content: '\e9dd'; }

.icon-bookmark:before {
  content: '\e9de'; }

.icon-bookmarks:before {
  content: '\e9df'; }

.icon-sun:before {
  content: '\e9e0'; }

.icon-contrast:before {
  content: '\e9e1'; }

.icon-brightness-contrast:before {
  content: '\e9e2'; }

.icon-star-empty:before {
  content: '\e9e3'; }

.icon-star-half:before {
  content: '\e9e4'; }

.icon-star-full:before {
  content: '\e9e5'; }

.icon-heart:before {
  content: '\e9e6'; }

.icon-heart-broken:before {
  content: '\e9e7'; }

.icon-man:before {
  content: '\e9e8'; }

.icon-woman:before {
  content: '\e9e9'; }

.icon-man-woman:before {
  content: '\e9ea'; }

.icon-happy:before {
  content: '\e9eb'; }

.icon-happy2:before {
  content: '\e9ec'; }

.icon-smile:before {
  content: '\e9ed'; }

.icon-smile2:before {
  content: '\e9ee'; }

.icon-tongue:before {
  content: '\e9ef'; }

.icon-tongue2:before {
  content: '\e9f0'; }

.icon-sad:before {
  content: '\e9f1'; }

.icon-sad2:before {
  content: '\e9f2'; }

.icon-wink:before {
  content: '\e9f3'; }

.icon-wink2:before {
  content: '\e9f4'; }

.icon-grin:before {
  content: '\e9f5'; }

.icon-grin2:before {
  content: '\e9f6'; }

.icon-cool:before {
  content: '\e9f7'; }

.icon-cool2:before {
  content: '\e9f8'; }

.icon-angry:before {
  content: '\e9f9'; }

.icon-angry2:before {
  content: '\e9fa'; }

.icon-evil:before {
  content: '\e9fb'; }

.icon-evil2:before {
  content: '\e9fc'; }

.icon-shocked:before {
  content: '\e9fd'; }

.icon-shocked2:before {
  content: '\e9fe'; }

.icon-baffled:before {
  content: '\e9ff'; }

.icon-baffled2:before {
  content: '\ea00'; }

.icon-confused:before {
  content: '\ea01'; }

.icon-confused2:before {
  content: '\ea02'; }

.icon-neutral:before {
  content: '\ea03'; }

.icon-neutral2:before {
  content: '\ea04'; }

.icon-hipster:before {
  content: '\ea05'; }

.icon-hipster2:before {
  content: '\ea06'; }

.icon-wondering:before {
  content: '\ea07'; }

.icon-wondering2:before {
  content: '\ea08'; }

.icon-sleepy:before {
  content: '\ea09'; }

.icon-sleepy2:before {
  content: '\ea0a'; }

.icon-frustrated:before {
  content: '\ea0b'; }

.icon-frustrated2:before {
  content: '\ea0c'; }

.icon-crying:before {
  content: '\ea0d'; }

.icon-crying2:before {
  content: '\ea0e'; }

.icon-point-up:before {
  content: '\ea0f'; }

.icon-point-right:before {
  content: '\ea10'; }

.icon-point-down:before {
  content: '\ea11'; }

.icon-point-left:before {
  content: '\ea12'; }

.icon-warning:before {
  content: '\ea13'; }

.icon-notification:before {
  content: '\ea14'; }

.icon-question:before {
  content: '\ea15'; }

.icon-plus:before {
  content: '\ea16'; }

.icon-minus:before {
  content: '\ea17'; }

.icon-info:before {
  content: '\ea18'; }

.icon-cancel-circle:before {
  content: '\ea19'; }

.icon-blocked:before {
  content: '\ea1a'; }

.icon-cross:before {
  content: '\ea1b'; }

.icon-checkmark:before {
  content: '\ea1c'; }

.icon-checkmark2:before {
  content: '\ea1d'; }

.icon-spell-check:before {
  content: '\ea1e'; }

.icon-enter:before {
  content: '\ea1f'; }

.icon-exit:before {
  content: '\ea20'; }

.icon-play2:before {
  content: '\ea21'; }

.icon-pause:before {
  content: '\ea22'; }

.icon-stop:before {
  content: '\ea23'; }

.icon-previous:before {
  content: '\ea24'; }

.icon-next:before {
  content: '\ea25'; }

.icon-backward:before {
  content: '\ea26'; }

.icon-forward2:before {
  content: '\ea27'; }

.icon-play3:before {
  content: '\ea28'; }

.icon-pause2:before {
  content: '\ea29'; }

.icon-stop2:before {
  content: '\ea2a'; }

.icon-backward2:before {
  content: '\ea2b'; }

.icon-forward3:before {
  content: '\ea2c'; }

.icon-first:before {
  content: '\ea2d'; }

.icon-last:before {
  content: '\ea2e'; }

.icon-previous2:before {
  content: '\ea2f'; }

.icon-next2:before {
  content: '\ea30'; }

.icon-eject:before {
  content: '\ea31'; }

.icon-volume-high:before {
  content: '\ea32'; }

.icon-volume-medium:before {
  content: '\ea33'; }

.icon-volume-low:before {
  content: '\ea34'; }

.icon-volume-mute:before {
  content: '\ea35'; }

.icon-volume-mute2:before {
  content: '\ea36'; }

.icon-volume-increase:before {
  content: '\ea37'; }

.icon-volume-decrease:before {
  content: '\ea38'; }

.icon-loop:before {
  content: '\ea39'; }

.icon-loop2:before {
  content: '\ea3a'; }

.icon-infinite:before {
  content: '\ea3b'; }

.icon-shuffle:before {
  content: '\ea3c'; }

.icon-arrow-up-left:before {
  content: '\ea3d'; }

.icon-arrow-up:before {
  content: '\ea3e'; }

.icon-arrow-up-right:before {
  content: '\ea3f'; }

.icon-arrow-right:before {
  content: '\ea40'; }

.icon-arrow-down-right:before {
  content: '\ea41'; }

.icon-arrow-down:before {
  content: '\ea42'; }

.icon-arrow-down-left:before {
  content: '\ea43'; }

.icon-arrow-left:before {
  content: '\ea44'; }

.icon-arrow-up-left2:before {
  content: '\ea45'; }

.icon-arrow-up2:before {
  content: '\ea46'; }

.icon-arrow-up-right2:before {
  content: '\ea47'; }

.icon-arrow-right2:before {
  content: '\ea48'; }

.icon-arrow-down-right2:before {
  content: '\ea49'; }

.icon-arrow-down2:before {
  content: '\ea4a'; }

.icon-arrow-down-left2:before {
  content: '\ea4b'; }

.icon-arrow-left2:before {
  content: '\ea4c'; }

.icon-circle-up:before {
  content: '\ea4d'; }

.icon-circle-right:before {
  content: '\ea4e'; }

.icon-circle-down:before {
  content: '\ea4f'; }

.icon-circle-left:before {
  content: '\ea50'; }

.icon-tab:before {
  content: '\ea51'; }

.icon-move-up:before {
  content: '\ea52'; }

.icon-move-down:before {
  content: '\ea53'; }

.icon-sort-alpha-asc:before {
  content: '\ea54'; }

.icon-sort-alpha-desc:before {
  content: '\ea55'; }

.icon-sort-numeric-asc:before {
  content: '\ea56'; }

.icon-sort-numberic-desc:before {
  content: '\ea57'; }

.icon-sort-amount-asc:before {
  content: '\ea58'; }

.icon-sort-amount-desc:before {
  content: '\ea59'; }

.icon-command:before {
  content: '\ea5a'; }

.icon-shift:before {
  content: '\ea5b'; }

.icon-ctrl:before {
  content: '\ea5c'; }

.icon-opt:before {
  content: '\ea5d'; }

.icon-checkbox-checked:before {
  content: '\ea5e'; }

.icon-checkbox-unchecked:before {
  content: '\ea5f'; }

.icon-radio-checked:before {
  content: '\ea60'; }

.icon-radio-checked2:before {
  content: '\ea61'; }

.icon-radio-unchecked:before {
  content: '\ea62'; }

.icon-crop:before {
  content: '\ea63'; }

.icon-make-group:before {
  content: '\ea64'; }

.icon-ungroup:before {
  content: '\ea65'; }

.icon-scissors:before {
  content: '\ea66'; }

.icon-filter:before {
  content: '\ea67'; }

.icon-font:before {
  content: '\ea68'; }

.icon-ligature:before {
  content: '\ea69'; }

.icon-ligature2:before {
  content: '\ea6a'; }

.icon-text-height:before {
  content: '\ea6b'; }

.icon-text-width:before {
  content: '\ea6c'; }

.icon-font-size:before {
  content: '\ea6d'; }

.icon-bold:before {
  content: '\ea6e'; }

.icon-underline:before {
  content: '\ea6f'; }

.icon-italic:before {
  content: '\ea70'; }

.icon-strikethrough:before {
  content: '\ea71'; }

.icon-omega:before {
  content: '\ea72'; }

.icon-sigma:before {
  content: '\ea73'; }

.icon-page-break:before {
  content: '\ea74'; }

.icon-superscript:before {
  content: '\ea75'; }

.icon-subscript:before {
  content: '\ea76'; }

.icon-superscript2:before {
  content: '\ea77'; }

.icon-subscript2:before {
  content: '\ea78'; }

.icon-text-color:before {
  content: '\ea79'; }

.icon-pagebreak:before {
  content: '\ea7a'; }

.icon-clear-formatting:before {
  content: '\ea7b'; }

.icon-table:before {
  content: '\ea7c'; }

.icon-table2:before {
  content: '\ea7d'; }

.icon-insert-template:before {
  content: '\ea7e'; }

.icon-pilcrow:before {
  content: '\ea7f'; }

.icon-ltr:before {
  content: '\ea80'; }

.icon-rtl:before {
  content: '\ea81'; }

.icon-section:before {
  content: '\ea82'; }

.icon-paragraph-left:before {
  content: '\ea83'; }

.icon-paragraph-center:before {
  content: '\ea84'; }

.icon-paragraph-right:before {
  content: '\ea85'; }

.icon-paragraph-justify:before {
  content: '\ea86'; }

.icon-indent-increase:before {
  content: '\ea87'; }

.icon-indent-decrease:before {
  content: '\ea88'; }

.icon-share:before {
  content: '\ea89'; }

.icon-new-tab:before {
  content: '\ea8a'; }

.icon-embed:before {
  content: '\ea8b'; }

.icon-embed2:before {
  content: '\ea8c'; }

.icon-terminal:before {
  content: '\ea8d'; }

.icon-share2:before {
  content: '\ea8e'; }

.icon-mail:before {
  content: '\ea8f'; }

.icon-mail2:before {
  content: '\ea90'; }

.icon-mail3:before {
  content: '\ea91'; }

.icon-mail4:before {
  content: '\ea92'; }

.icon-amazon:before {
  content: '\ea93'; }

.icon-google:before {
  content: '\ea94'; }

.icon-google2:before {
  content: '\ea95'; }

.icon-google3:before {
  content: '\ea96'; }

.icon-google-plus:before {
  content: '\ea97'; }

.icon-google-plus2:before {
  content: '\ea98'; }

.icon-google-plus3:before {
  content: '\ea99'; }

.icon-hangouts:before {
  content: '\ea9a'; }

.icon-google-drive:before {
  content: '\ea9b'; }

.icon-facebook:before {
  content: '\ea9c'; }

.icon-facebook2:before {
  content: '\ea9d'; }

.icon-instagram:before {
  content: '\ea9e'; }

.icon-whatsapp:before {
  content: '\ea9f'; }

.icon-spotify:before {
  content: '\eaa0'; }

.icon-telegram:before {
  content: '\eaa1'; }

.icon-twitter:before {
  content: '\eaa2'; }

.icon-vine:before {
  content: '\eaa3'; }

.icon-vk:before {
  content: '\eaa4'; }

.icon-renren:before {
  content: '\eaa5'; }

.icon-sina-weibo:before {
  content: '\eaa6'; }

.icon-rss:before {
  content: '\eaa7'; }

.icon-rss2:before {
  content: '\eaa8'; }

.icon-youtube:before {
  content: '\eaa9'; }

.icon-youtube2:before {
  content: '\eaaa'; }

.icon-twitch:before {
  content: '\eaab'; }

.icon-vimeo:before {
  content: '\eaac'; }

.icon-vimeo2:before {
  content: '\eaad'; }

.icon-lanyrd:before {
  content: '\eaae'; }

.icon-flickr:before {
  content: '\eaaf'; }

.icon-flickr2:before {
  content: '\eab0'; }

.icon-flickr3:before {
  content: '\eab1'; }

.icon-flickr4:before {
  content: '\eab2'; }

.icon-dribbble:before {
  content: '\eab3'; }

.icon-behance:before {
  content: '\eab4'; }

.icon-behance2:before {
  content: '\eab5'; }

.icon-deviantart:before {
  content: '\eab6'; }

.icon-500px:before {
  content: '\eab7'; }

.icon-steam:before {
  content: '\eab8'; }

.icon-steam2:before {
  content: '\eab9'; }

.icon-dropbox:before {
  content: '\eaba'; }

.icon-onedrive:before {
  content: '\eabb'; }

.icon-github:before {
  content: '\eabc'; }

.icon-npm:before {
  content: '\eabd'; }

.icon-basecamp:before {
  content: '\eabe'; }

.icon-trello:before {
  content: '\eabf'; }

.icon-wordpress:before {
  content: '\eac0'; }

.icon-joomla:before {
  content: '\eac1'; }

.icon-ello:before {
  content: '\eac2'; }

.icon-blogger:before {
  content: '\eac3'; }

.icon-blogger2:before {
  content: '\eac4'; }

.icon-tumblr:before {
  content: '\eac5'; }

.icon-tumblr2:before {
  content: '\eac6'; }

.icon-yahoo:before {
  content: '\eac7'; }

.icon-yahoo2:before {
  content: '\eac8'; }

.icon-tux:before {
  content: '\eac9'; }

.icon-appleinc:before {
  content: '\eaca'; }

.icon-finder:before {
  content: '\eacb'; }

.icon-android:before {
  content: '\eacc'; }

.icon-windows:before {
  content: '\eacd'; }

.icon-windows8:before {
  content: '\eace'; }

.icon-soundcloud:before {
  content: '\eacf'; }

.icon-soundcloud2:before {
  content: '\ead0'; }

.icon-skype:before {
  content: '\ead1'; }

.icon-reddit:before {
  content: '\ead2'; }

.icon-hackernews:before {
  content: '\ead3'; }

.icon-wikipedia:before {
  content: '\ead4'; }

.icon-linkedin:before {
  content: '\ead5'; }

.icon-linkedin2:before {
  content: '\ead6'; }

.icon-lastfm:before {
  content: '\ead7'; }

.icon-lastfm2:before {
  content: '\ead8'; }

.icon-delicious:before {
  content: '\ead9'; }

.icon-stumbleupon:before {
  content: '\eada'; }

.icon-stumbleupon2:before {
  content: '\eadb'; }

.icon-stackoverflow:before {
  content: '\eadc'; }

.icon-pinterest:before {
  content: '\eadd'; }

.icon-pinterest2:before {
  content: '\eade'; }

.icon-xing:before {
  content: '\eadf'; }

.icon-xing2:before {
  content: '\eae0'; }

.icon-flattr:before {
  content: '\eae1'; }

.icon-foursquare:before {
  content: '\eae2'; }

.icon-yelp:before {
  content: '\eae3'; }

.icon-paypal:before {
  content: '\eae4'; }

.icon-chrome:before {
  content: '\eae5'; }

.icon-firefox:before {
  content: '\eae6'; }

.icon-IE:before {
  content: '\eae7'; }

.icon-edge:before {
  content: '\eae8'; }

.icon-safari:before {
  content: '\eae9'; }

.icon-opera:before {
  content: '\eaea'; }

.icon-file-pdf:before {
  content: '\eaeb'; }

.icon-file-openoffice:before {
  content: '\eaec'; }

.icon-file-word:before {
  content: '\eaed'; }

.icon-file-excel:before {
  content: '\eaee'; }

.icon-libreoffice:before {
  content: '\eaef'; }

.icon-html-five:before {
  content: '\eaf0'; }

.icon-html-five2:before {
  content: '\eaf1'; }

.icon-css3:before {
  content: '\eaf2'; }

.icon-git:before {
  content: '\eaf3'; }

.icon-codepen:before {
  content: '\eaf4'; }

.icon-svg:before {
  content: '\eaf5'; }

.icon-IcoMoon:before {
  content: '\eaf6'; }

* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }

input,
textarea,
button {
  font-family: "Nexa", sans-serif;
  -webkit-appearance: none;
  text-rendering: auto;
  text-indent: 0px; }

a {
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-feature-settings: 'kern' 1;
  font-kerning: normal; }

img {
  border: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nexa", sans-serif;
  font-weight: 600;
  padding: 0;
  margin: 0 0 15px 0; }

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-weight: 400;
  font-family: "Nexa", sans-serif;
  color: #6c6c6c;
  background: #ffffff;
  -webkit-font-smoothing: antialiased; }
  body.no-scroll {
    position: fixed;
    height: 100vh;
    overflow: hidden; }

.title {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 700;
  font-size: 54px;
  font-size: 3.375rem;
  line-height: 59px;
  line-height: 3.6875rem;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  color: #21315f; }
  @media only screen and (max-width: 1024px) {
    .title {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 44px;
      line-height: 2.75rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; } }
  .title span {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    font-size: 1rem;
    line-height: 25.6px;
    line-height: 1.6rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    color: #ff8300;
    text-align: center; }
    @media only screen and (max-width: 1024px) {
      .title span {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 22.4px;
        line-height: 1.4rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
  .title.-home-project {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 44px;
    line-height: 2.75rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal; }
    .title.-home-project span {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 22.4px;
      line-height: 1.4rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }

.container {
  width: 100%;
  max-width: 1410px;
  padding: 0 60px;
  margin: 0 auto; }
  @media only screen and (max-width: 1024px) {
    .container {
      padding: 0 20px; } }
  .container-full  {
    max-width: 100%;
    padding: 0 60px; }
    @media only screen and (max-width: 1024px) {
      .container-full  {
        padding: 0 20px; } }

/*
 * Header Style
 * 
 */
.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 135px;
  margin: 0;
  padding: 0 60px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, height 0.3s ease-in-out; }
  @media only screen and (max-width: 1440px) {
    .header {
      height: 120px; } }
  @media only screen and (max-width: 1200px) {
    .header {
      justify-content: space-between;
      height: 80px; } }
  @media only screen and (max-width: 1024px) {
    .header {
      padding: 0 20px; } }
  @media only screen and (max-width: 560px) {
    .header {
      background-color: #ffffff; }
      .header .nav__link  {
        color: #21315f; }
      .header .header__logo svg {
        fill: #21315f; }
        .header .header__logo svg polygon,
        .header .header__logo svg path,
        .header .header__logo svg rect {
          fill: #21315f; }
      .header .header__language__item {
        color: #6c6c6c; }
      .header .header__menu__item {
        background-color: #21315f; } }
  .header-page, .header.-sticky {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    height: 80px; }
    @media only screen and (max-width: 1440px) {
      .header-page, .header.-sticky {
        height: 80px; } }
    .header-page .header__menu__item, .header.-sticky .header__menu__item {
      background-color: #21315f; }
    .header-page .header__logo svg, .header.-sticky .header__logo svg {
      max-width: 200px; }
      @media only screen and (max-width: 1024px) {
        .header-page .header__logo svg, .header.-sticky .header__logo svg {
          max-width: 150px; } }
    .header-page .header__nav .nav__link , .header.-sticky .header__nav .nav__link  {
      height: 80px; }
    .header-page .header__nav .nav-content, .header.-sticky .header__nav .nav-content {
      top: 80px; }
  @media only screen and (max-width: 1200px) {
    .header.-mobile-menu {
      background-color: #ffffff;
      border-bottom: 1px solid #f3f3f3; }
      .header.-mobile-menu .nav__link  {
        color: #21315f; }
      .header.-mobile-menu .header__logo svg {
        fill: #21315f; }
        .header.-mobile-menu .header__logo svg polygon,
        .header.-mobile-menu .header__logo svg path,
        .header.-mobile-menu .header__logo svg rect {
          fill: #21315f; }
      .header.-mobile-menu .header__language__item {
        color: #6c6c6c; }
      .header.-mobile-menu .header__menu__item {
        background-color: #21315f; }
      .header.-mobile-menu .header__menu__item:nth-child(1) {
        transform: rotate(45deg) translateX(5px) translateY(5px); }
      .header.-mobile-menu .header__menu__item:nth-child(2) {
        opacity: 0; }
      .header.-mobile-menu .header__menu__item:nth-child(3) {
        transform: rotate(-45deg) translateX(5px) translateY(-5px); } }
  .header.-sticky, .header.-menu {
    background-color: #ffffff; }
    .header.-sticky .nav__link , .header.-menu .nav__link  {
      color: #21315f; }
    .header.-sticky .header__logo svg, .header.-menu .header__logo svg {
      fill: #21315f; }
      .header.-sticky .header__logo svg polygon,
      .header.-sticky .header__logo svg path,
      .header.-sticky .header__logo svg rect, .header.-menu .header__logo svg polygon,
      .header.-menu .header__logo svg path,
      .header.-menu .header__logo svg rect {
        fill: #21315f; }
    .header.-sticky .header__language__item, .header.-menu .header__language__item {
      color: #6c6c6c; }
    .header.-sticky .header__menu__item, .header.-menu .header__menu__item {
      background-color: #21315f; }
  .header-page {
    position: sticky;
    background-color: #ffffff;
    border-bottom: 1px solid #dbdbdb; }
    .header-page.-sticky {
      position: sticky; }
    .header-page .header__nav .nav__link  {
      color: #21315f; }
    .header-page .header__logo svg {
      fill: #21315f; }
      .header-page .header__logo svg polygon,
      .header-page .header__logo svg path,
      .header-page .header__logo svg rect {
        fill: #21315f; }
    .header-page .header__language__item {
      color: #6c6c6c; }
  .header.-search {
    background-color: transparent;
    border-color: #ffffff; }
    .header.-search .nav__link {
      opacity: 0;
      pointer-events: none; }
    .header.-search .header__search .icon-sigma-close {
      display: block; }
    .header.-search .header__search .icon-sigma-search {
      display: none; }
    .header.-search .header__logo svg {
      fill: #21315f; }
      .header.-search .header__logo svg polygon,
      .header.-search .header__logo svg path,
      .header.-search .header__logo svg rect {
        fill: #21315f; }
    .header.-search .header__language__item {
      opacity: 0;
      pointer-events: none; }
    .header.-search .header__menu__item {
      background-color: #21315f; }
  .header__logo {
    flex-grow: 0;
    flex-shrink: 0; }
    .header__logo svg {
      fill: #ffffff;
      display: block;
      width: 100%;
      max-width: 240px;
      transition: max-width 0.3s ease-in-out, fill 0.3s ease-in-out; }
      @media only screen and (max-width: 1440px) {
        .header__logo svg {
          max-width: 200px; } }
      @media only screen and (max-width: 1024px) {
        .header__logo svg {
          max-width: 150px; } }
      .header__logo svg polygon,
      .header__logo svg path,
      .header__logo svg rect {
        fill: #ffffff;
        transition: fill 0.3s ease-in-out; }
  .header__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1200px) {
      .header__nav {
        display: none; } }
    .header__nav .nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .header__nav .nav__item:hover .nav__link {
        color: #ff8300; }
      .header__nav .nav__item:hover .nav-content {
        width: 100%;
        height: auto;
        padding: 80px 0;
        opacity: 1;
        visibility: visible; }
      .header__nav .nav__link {
        position: relative;
        height: 135px;
        margin: 0 15px;
        padding-top: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 22.4px;
        line-height: 1.4rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        font-weight: 600;
        color: #ffffff;
        transition: color 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.2s ease-in-out; }
        @media only screen and (max-width: 1440px) {
          .header__nav .nav__link {
            height: 120px;
            margin: 0 10px;
            font-size: 13px;
            font-size: 0.8125rem;
            line-height: 20.8px;
            line-height: 1.3rem;
            font-feature-settings: 'kern' 1;
            font-kerning: normal; } }
        .header__nav .nav__link.-active {
          color: #ff8300; }
          .header__nav .nav__link.-active:after {
            opacity: 1; }
        .header__nav .nav__link:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 100%;
          height: 3px;
          background-color: #ff8300;
          opacity: 0;
          transition: opacity 0.3s ease-in-out; }
    .header__nav .nav-sub {
      margin: 0 0 20px 0;
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 700;
      color: #ffffff; }
      .header__nav .nav-sub-mb {
        margin: 0 0 40px 0; }
      .header__nav .nav-sub__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .header__nav .nav-sub__list__link {
          position: relative;
          margin-bottom: 8px;
          padding: 3px 0 3px 20px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 12px;
          font-size: 0.75rem;
          line-height: 19.2px;
          line-height: 1.2rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          font-weight: 500;
          color: #ffffff;
          transition: color 0.2s ease-in-out; }
          .header__nav .nav-sub__list__link:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #ff8300;
            opacity: 0;
            transition: opacity 0.2s ease-in-out; }
          .header__nav .nav-sub__list__link.-hover {
            color: #ff8300; }
          .header__nav .nav-sub__list__link.-active {
            color: #ff8300; }
            .header__nav .nav-sub__list__link.-active:after {
              opacity: 1; }
    .header__nav .nav-content {
      position: absolute;
      top: 135px;
      left: 0;
      z-index: 2;
      width: 0;
      height: 0;
      padding: 0;
      background-image: url("../svg/menu-bg.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #21315f;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, top 0.3s ease-in-out; }
      @media only screen and (max-width: 1440px) {
        .header__nav .nav-content {
          top: 120px; } }
      .header__nav .nav-content__container {
        width: 100%;
        max-width: 1250px;
        margin: auto;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: flex-start;
        overflow: hidden; }
      .header__nav .nav-content__titles {
        width: 100%;
        max-width: 370px;
        border-right: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
      .header__nav .nav-content__list {
        width: 0;
        height: 0;
        padding-left: 70px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out; }
        .header__nav .nav-content__list.-active {
          width: 100%;
          height: auto;
          opacity: 1;
          visibility: visible; }
        .header__nav .nav-content__list__item {
          width: calc(100% / 3);
          padding: 10px;
          color: #ffffff;
          font-size: 13px;
          font-size: 0.8125rem;
          line-height: 20.8px;
          line-height: 1.3rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          font-weight: 400;
          transition: color 0.2s ease-in-out; }
          .header__nav .nav-content__list__item:hover, .header__nav .nav-content__list__item.-active {
            color: #ff8300; }
  .header__group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0; }
  .header__language {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; }
    @media only screen and (max-width: 1200px) {
      .header__language {
        display: none; } }
    .header__language__item {
      padding-top: 3px;
      margin-right: 15px;
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 19.2px;
      line-height: 1.2rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.6;
      transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out; }
      @media only screen and (max-width: 1440px) {
        .header__language__item {
          margin-right: 10px;
          font-size: 11px;
          font-size: 0.6875rem;
          line-height: 17.6px;
          line-height: 1.1rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .header__language__item:hover {
        opacity: 1; }
      .header__language__item.-active {
        opacity: 1;
        font-weight: 700; }
      .header__language__item:last-child {
        margin-right: 0; }
  .header__search {
    width: 35px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ff8300;
    cursor: pointer; }
    .header__search .icon-sigma-close {
      display: none;
      color: #ffffff;
      font-size: 14px; }
    .header__search .icon-sigma-search {
      color: #ffffff;
      font-size: 17px; }
  .header__menu {
    position: relative;
    width: 20px;
    height: 16px;
    margin-left: 20px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer; }
    @media only screen and (max-width: 1200px) {
      .header__menu {
        display: flex; } }
    .header__menu__item {
      width: 100%;
      height: 2px;
      display: flex;
      background-color: #ffffff;
      pointer-events: none;
      transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out; }

/*
 * Footer Style
 * 
 */
.footer {
  width: 100%;
  padding: 70px 0 30px 0;
  background-color: #21315f; }
  .footer__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 1024px) {
      .footer__row {
        flex-direction: column; } }
    .footer__row.-bottom {
      padding-top: 50px; }
  .footer__nav {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media only screen and (max-width: 1024px) {
      .footer__nav {
        width: 100%;
        align-items: center;
        margin-bottom: 30px; } }
    .footer__nav__title {
      margin-bottom: 15px;
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 28.8px;
      line-height: 1.8rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      color: #ffffff; }
      @media only screen and (max-width: 1024px) {
        .footer__nav__title {
          text-align: center; } }
    .footer__nav__link {
      margin-bottom: 10px;
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 19.2px;
      line-height: 1.2rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      color: #ffffff; }
      @media only screen and (max-width: 1024px) {
        .footer__nav__link {
          text-align: center; } }
      .footer__nav__link:hover {
        text-decoration: underline; }
  .footer__logo {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media only screen and (max-width: 1024px) {
      .footer__logo {
        width: 100%;
        align-items: center;
        margin-bottom: 50px;
        padding-right: 0; } }
    .footer__logo__svg {
      margin-bottom: 30px; }
      .footer__logo__svg svg {
        width: 100%;
        max-width: 240px;
        display: block; }
        .footer__logo__svg svg path,
        .footer__logo__svg svg rect {
          fill: #ffffff; }
    .footer__logo__text {
      width: 100%;
      max-width: 300px;
      color: #ffffff;
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 19.2px;
      line-height: 1.2rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      @media only screen and (max-width: 1024px) {
        .footer__logo__text {
          max-width: 400px;
          text-align: center; } }
  .footer__contact {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end; }
    @media only screen and (max-width: 1024px) {
      .footer__contact {
        width: 100%;
        max-width: 100%;
        padding-top: 20px;
        margin-bottom: 20px;
        align-items: center; } }
    .footer__contact__title {
      margin-bottom: 30px;
      font-size: 34px;
      font-size: 2.125rem;
      line-height: 54.4px;
      line-height: 3.4rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 700;
      color: #ffffff;
      text-decoration: underline;
      text-align: right; }
      @media only screen and (max-width: 1024px) {
        .footer__contact__title {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 48px;
          line-height: 3rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          text-align: center; } }
    .footer__contact__address {
      margin-bottom: 10px;
      text-align: right;
      color: #ffffff;
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 19.2px;
      line-height: 1.2rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      @media only screen and (max-width: 1024px) {
        .footer__contact__address {
          text-align: center; } }
      .footer__contact__address strong {
        font-weight: 600; }
      .footer__contact__address a {
        color: #ffffff;
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 19.2px;
        line-height: 1.2rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; }
        .footer__contact__address a:hover {
          text-decoration: underline; }
  .footer__text {
    color: #ffffff;
    font-size: 11px;
    font-size: 0.6875rem;
    line-height: 17.6px;
    line-height: 1.1rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal; }
    @media only screen and (max-width: 1024px) {
      .footer__text {
        width: 100%;
        text-align: center;
        margin-bottom: 10px; } }
    .footer__text a {
      color: #ffffff;
      font-size: 11px;
      font-size: 0.6875rem;
      line-height: 17.6px;
      line-height: 1.1rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      .footer__text a:hover {
        text-decoration: underline; }
  .footer__menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1024px) {
      .footer__menu {
        width: 100%;
        margin-bottom: 10px;
        flex-wrap: wrap; } }
    .footer__menu__link {
      padding: 0 8px;
      color: #ffffff;
      font-size: 11px;
      font-size: 0.6875rem;
      line-height: 17.6px;
      line-height: 1.1rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      @media only screen and (max-width: 1024px) {
        .footer__menu__link {
          margin-bottom: 5px;
          text-align: center; } }
      .footer__menu__link:hover {
        text-decoration: underline; }
  .footer__socialmedia {
    padding: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .footer__socialmedia__item {
      width: 28px;
      height: 28px;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 50%;
      cursor: pointer; }
      .footer__socialmedia__item span  {
        font-size: 14px;
        color: #21315f; }

/*
 * Button Style
 * 
 */
.button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  text-decoration: none;
  appearance: none;
  padding: 10px 0;
  border-bottom: 2px solid #ffffff;
  transition: border-bottom 0.2s ease-in-out; }
  .button-no-border {
    border-bottom: 0; }
  .button-color-grey .button__arrow,
  .button-color-grey .button__text {
    color: #6c6c6c;
    font-weight: 700; }
  .button-color-grey:hover .button__arrow,
  .button-color-grey:hover .button__text {
    color: #ff8300; }
  .button-color-blue {
    border-bottom: 2px solid #21315f; }
    .button-color-blue .button__arrow,
    .button-color-blue .button__text {
      color: #21315f;
      font-weight: 700; }
    .button-color-blue:hover {
      border-bottom: 2px solid #ff8300; }
      .button-color-blue:hover .button__arrow,
      .button-color-blue:hover .button__text {
        color: #ff8300; }
  @media only screen and (max-width: 768px) {
    .button {
      padding: 8px 0; } }
  .button__text {
    margin-right: 40px;
    display: inline-block;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 24px;
    line-height: 1.5rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 600;
    color: #ffffff;
    transition: color 0.2s ease-in-out; }
    @media only screen and (max-width: 768px) {
      .button__text {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 22.4px;
        line-height: 1.4rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
  .button__arrow {
    font-size: 18px;
    color: #ffffff;
    transition: color 0.2s ease-in-out; }

/*
 * Menu Style
 * 
 */
.overlay-menu {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 4;
  width: 0;
  height: calc(100vh - 80px);
  padding-bottom: 100px;
  display: none;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: visible;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out; }
  @media only screen and (max-width: 1200px) {
    .overlay-menu {
      display: block; } }
  .overlay-menu-open {
    width: 100%;
    opacity: 1;
    visibility: visible; }
    .overlay-menu-open .overlay-menu__container {
      opacity: 1;
      visibility: visible; }
  .overlay-menu__container {
    width: 100%;
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out; }
    @media only screen and (max-width: 1024px) {
      .overlay-menu__container {
        padding: 50px 20px; } }
    @media only screen and (max-width: 560px) {
      .overlay-menu__container {
        padding: 20px; } }
  .overlay-menu__language {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; }
    @media only screen and (max-width: 768px) {
      .overlay-menu__language {
        padding-top: 0;
        order: 1; } }
    .overlay-menu__language__item {
      margin-right: 30px;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 22.4px;
      line-height: 1.4rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 600;
      color: #6c6c6c;
      text-decoration: none;
      opacity: 0.6;
      transition: color 0.3s ease-in-out; }
      @media only screen and (max-width: 1248px) {
        .overlay-menu__language__item {
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 22.4px;
          line-height: 1.4rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .overlay-menu__language__item:hover {
        color: #21315f;
        opacity: 1; }
      .overlay-menu__language__item:last-child {
        margin-right: 0; }
      .overlay-menu__language__item.-active {
        color: #21315f;
        opacity: 1; }
  .overlay-menu__nav {
    width: 100%;
    padding: 0 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media only screen and (max-width: 768px) {
      .overlay-menu__nav {
        width: 100%; } }
    .overlay-menu__nav__item {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 20px 0 13px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 26px;
      line-height: 1.625rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      color: #21315f;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      transition: color 0.2s ease-in-out; }
      @media only screen and (max-width: 1024px) {
        .overlay-menu__nav__item {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 24px;
          line-height: 1.5rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 560px) {
        .overlay-menu__nav__item {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 22px;
          line-height: 1.375rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .overlay-menu__nav__item:hover {
        color: #ff8300; }
  .overlay-menu__sub {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 80px);
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden; }
    .overlay-menu__sub--open {
      opacity: 1;
      visibility: visible; }
    @media only screen and (max-width: 1024px) {
      .overlay-menu__sub {
        padding: 50px 20px; } }
    @media only screen and (max-width: 560px) {
      .overlay-menu__sub {
        padding: 20px 20px 100px 20px; } }
    .overlay-menu__sub__back {
      padding-bottom: 20px;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      color: #21315f;
      font-weight: 500;
      cursor: pointer; }
      .overlay-menu__sub__back span {
        margin-right: 5px;
        font-size: 13px; }
    .overlay-menu__sub__title {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #f3f3f3;
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 26px;
      line-height: 1.625rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      color: #ff8300; }
      @media only screen and (max-width: 1024px) {
        .overlay-menu__sub__title {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 24px;
          line-height: 1.5rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 560px) {
        .overlay-menu__sub__title {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 22px;
          line-height: 1.375rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
    .overlay-menu__sub__category {
      width: 100%;
      padding: 10px 0;
      font-size: 16px;
      font-size: 1rem;
      line-height: 25.6px;
      line-height: 1.6rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 600;
      color: #21315f;
      border-bottom: 1px solid #f3f3f3; }
      .overlay-menu__sub__category.-sub {
        border-bottom: 0; }
    .overlay-menu__sub__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 0;
      flex-shrink: 0;
      border-bottom: 1px solid #f3f3f3; }
    .overlay-menu__sub__link {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 20.8px;
      line-height: 1.3rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      color: #21315f;
      cursor: pointer; }
      .overlay-menu__sub__link span {
        font-size: 12px; }
    .overlay-menu__sub__list {
      width: 100%;
      height: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 0;
      flex-shrink: 0;
      overflow: hidden;
      transition: height 0.2s ease-in-out; }
      .overlay-menu__sub__list__link {
        padding: 5px 10px;
        color: #ff8300; }
    .overlay-menu__sub__group {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 0;
      flex-shrink: 0; }

.page-banner {
  padding: 50px 0; }
  .page-banner--full .page-banner__wrapper {
    padding: 30px 0;
    height: 430px;
    justify-content: flex-end; }
    @media only screen and (max-width: 1248px) {
      .page-banner--full .page-banner__wrapper {
        height: 400px; } }
    @media only screen and (max-width: 1200px) {
      .page-banner--full .page-banner__wrapper {
        height: 300px; } }
    @media only screen and (max-width: 1024px) {
      .page-banner--full .page-banner__wrapper {
        height: auto;
        min-height: 300px; } }
    @media only screen and (max-width: 560px) {
      .page-banner--full .page-banner__wrapper {
        min-height: 280px; } }
  .page-banner--full .container {
    z-index: 2; }
  .page-banner--center .page-banner__wrapper {
    justify-content: center; }
  .page-banner__wrapper {
    position: relative;
    width: 100%;
    height: 500px;
    margin: 0;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 1248px) {
      .page-banner__wrapper {
        height: 400px; } }
    @media only screen and (max-width: 1200px) {
      .page-banner__wrapper {
        height: 300px; } }
    @media only screen and (max-width: 1024px) {
      .page-banner__wrapper {
        padding: 30px 20px;
        height: auto;
        min-height: 300px; } }
    @media only screen and (max-width: 560px) {
      .page-banner__wrapper {
        min-height: 280px; } }
    .page-banner__wrapper:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #21315f;
      opacity: 0.5; }
  .page-banner__title {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 65px;
    line-height: 4.0625rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    color: #ffffff;
    font-weight: 700; }
    @media only screen and (max-width: 1200px) {
      .page-banner__title {
        font-size: 45px;
        font-size: 2.8125rem;
        line-height: 55px;
        line-height: 3.4375rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
    @media only screen and (max-width: 1024px) {
      .page-banner__title {
        padding: 0; } }
    @media only screen and (max-width: 560px) {
      .page-banner__title {
        padding: 30px 0;
        font-size: 35px;
        font-size: 2.1875rem;
        line-height: 45px;
        line-height: 2.8125rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
    .page-banner__title span {
      margin-bottom: 20px;
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 28.8px;
      line-height: 1.8rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      color: #ffffff;
      font-weight: 400; }
      @media only screen and (max-width: 1200px) {
        .page-banner__title span {
          margin-bottom: 15px;
          font-size: 16px;
          font-size: 1rem;
          line-height: 25.6px;
          line-height: 1.6rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 560px) {
        .page-banner__title span {
          margin-bottom: 10px;
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 22.4px;
          line-height: 1.4rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }

.page-content {
  width: 100%;
  max-width: 850px;
  padding: 0 20px 90px 20px;
  margin: auto; }
  .page-content--wide {
    width: 100%;
    max-width: 1410px;
    margin: auto;
    padding: 60px; }
    @media only screen and (max-width: 1024px) {
      .page-content--wide {
        padding: 50px 20px; } }
  @media only screen and (max-width: 560px) {
    .page-content {
      padding: 50px 20px; } }

.page-sticky {
  position: sticky;
  top: 80px;
  z-index: 3;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #21315f; }
  @media only screen and (max-width: 560px) {
    .page-sticky {
      height: 60px; } }
  .page-sticky__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .page-sticky__item:after {
      content: '';
      width: 1px;
      height: 25px;
      margin: 0 35px;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      background-color: #ffffff;
      opacity: 0.4; }
      @media only screen and (max-width: 560px) {
        .page-sticky__item:after {
          margin: 0 25px; } }
    .page-sticky__item:last-child:after {
      display: none; }
    .page-sticky__item.-active .page-sticky__item__link {
      opacity: 1; }
    .page-sticky__item__link {
      padding: 4px 0 0 0;
      color: #ffffff;
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 22px;
      line-height: 1.375rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      opacity: 0.4;
      transition: opacity 0.2s ease-in-out; }
      @media only screen and (max-width: 560px) {
        .page-sticky__item__link {
          font-size: 12px;
          font-size: 0.75rem;
          line-height: 20px;
          line-height: 1.25rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .page-sticky__item__link:hover {
        opacity: 1; }

.information {
  width: 100%;
  max-width: 1410px;
  padding: 60px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff8300; }
  @media only screen and (max-width: 1024px) {
    .information {
      padding: 40px 20px; } }
  .information__text {
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 700;
    text-align: center; }
    @media only screen and (max-width: 1024px) {
      .information__text {
        font-size: 25px;
        font-size: 1.5625rem;
        line-height: 35px;
        line-height: 2.1875rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
  .information__button {
    padding: 19px 30px 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    text-align: center;
    color: #ff8300;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22.4px;
    line-height: 1.4rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 500; }

/*
 * Project Slider Style
 * 
 */
.project-slider {
  width: 100%;
  overflow: hidden;
  padding: 10px 0 120px 0; }
  .project-slider .container-full {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .project-slider__title {
    position: relative;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    font-size: 46px;
    font-size: 2.875rem;
    line-height: 70px;
    line-height: 4.375rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    color: #6c6c6c; }
    @media only screen and (max-width: 1024px) {
      .project-slider__title {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 60px;
        line-height: 3.75rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
    @media only screen and (max-width: 768px) {
      .project-slider__title {
        font-size: 35px;
        font-size: 2.1875rem;
        line-height: 40px;
        line-height: 2.5rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
    .project-slider__title strong {
      color: #21315f; }
      @media only screen and (max-width: 768px) {
        .project-slider__title strong {
          padding-top: 10px; } }
    .project-slider__title .project-video {
      position: absolute;
      top: -5px;
      right: -80px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      padding: 1px;
      border: 1px solid #21315f;
      cursor: pointer; }
      @media only screen and (max-width: 1024px) {
        .project-slider__title .project-video {
          position: relative;
          top: auto;
          right: auto; } }
      @media only screen and (max-width: 768px) {
        .project-slider__title .project-video {
          margin-top: 15px; } }
      .project-slider__title .project-video span {
        position: absolute;
        left: 22px;
        top: 20px;
        z-index: 1;
        font-size: 24px;
        color: #ffffff; }
      .project-slider__title .project-video img {
        display: block;
        width: 100%;
        object-fit: cover; }
  .project-slider__slider .container-full {
    justify-content: space-between; }
    @media only screen and (max-width: 1024px) {
      .project-slider__slider .container-full {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; } }
  .project-slider__slider .slider-title {
    width: 100%;
    max-width: 500px; }
  .project-slider__slider .slider-all-project {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #6c6c6c;
    font-size: 25px;
    font-size: 1.5625rem;
    line-height: 40px;
    line-height: 2.5rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.2s ease-in-out; }
    @media only screen and (max-width: 1024px) {
      .project-slider__slider .slider-all-project {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 32px;
        line-height: 2rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
    .project-slider__slider .slider-all-project:hover {
      color: #ff8300; }
      .project-slider__slider .slider-all-project:hover span {
        color: #ff8300; }
    .project-slider__slider .slider-all-project span {
      margin-left: 15px;
      display: inline-block;
      font-size: 17px;
      color: #6c6c6c;
      transition: color 0.2s ease-in-out; }
      @media only screen and (max-width: 1024px) {
        .project-slider__slider .slider-all-project span {
          margin-left: 12px;
          font-size: 14px; } }
  .project-slider__slider .slider-project {
    padding: 40px 0 0 0; }
    .project-slider__slider .slider-project__wrapper {
      align-items: center; }
    .project-slider__slider .slider-project__item {
      position: relative;
      width: 50%;
      background-color: #ffffff;
      padding: 0 70px; }
      @media only screen and (max-width: 1440px) {
        .project-slider__slider .slider-project__item {
          padding: 0 50px; } }
      @media only screen and (max-width: 1200px) {
        .project-slider__slider .slider-project__item {
          padding: 0 30px; } }
      @media only screen and (max-width: 768px) {
        .project-slider__slider .slider-project__item {
          padding: 0 20px; } }
      .project-slider__slider .slider-project__item__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 15px 0; }
        @media only screen and (max-width: 768px) {
          .project-slider__slider .slider-project__item__content {
            flex-direction: column; } }
        .project-slider__slider .slider-project__item__content .project-title {
          color: #21315f;
          font-size: 22px;
          font-size: 1.375rem;
          line-height: 28px;
          line-height: 1.75rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          font-weight: 600; }
          @media only screen and (max-width: 768px) {
            .project-slider__slider .slider-project__item__content .project-title {
              order: 2; } }
        .project-slider__slider .slider-project__item__content .project-category {
          padding: 2px 0 0 10px;
          color: #6c6c6c;
          font-size: 12px;
          font-size: 0.75rem;
          line-height: 19.2px;
          line-height: 1.2rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          font-weight: 400;
          flex-grow: 0;
          flex-shrink: 0; }
          @media only screen and (max-width: 768px) {
            .project-slider__slider .slider-project__item__content .project-category {
              padding: 5px 0;
              order: 1; } }
        .project-slider__slider .slider-project__item__content .project-year {
          position: absolute;
          top: 6px;
          right: 50px;
          color: #6c6c6c;
          font-size: 12px;
          font-size: 0.75rem;
          line-height: 19.2px;
          line-height: 1.2rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          font-weight: 400;
          transform: rotate(90deg); }
          @media only screen and (max-width: 1440px) {
            .project-slider__slider .slider-project__item__content .project-year {
              right: 30px; } }
          @media only screen and (max-width: 1200px) {
            .project-slider__slider .slider-project__item__content .project-year {
              right: 10px; } }
          @media only screen and (max-width: 768px) {
            .project-slider__slider .slider-project__item__content .project-year {
              right: 0; } }
      .project-slider__slider .slider-project__item__image {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        object-fit: cover;
        transition: box-shadow 0.3s ease-in-out; }
        .project-slider__slider .slider-project__item__image:hover {
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.2); }
          @media only screen and (max-width: 1200px) {
            .project-slider__slider .slider-project__item__image:hover {
              box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.3); } }
        @media only screen and (max-width: 1024px) {
          .project-slider__slider .slider-project__item__image {
            height: auto;
            min-height: 300px; } }
    .project-slider__slider .slider-project__nav {
      top: 40%;
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity: 1 !important;
      background-color: rgba(255, 255, 255, 0.3);
      transition: background-color 0.3s linear; }
      @media only screen and (max-width: 1024px) {
        .project-slider__slider .slider-project__nav {
          display: none; } }
      .project-slider__slider .slider-project__nav:hover {
        background-color: rgba(255, 255, 255, 0.8); }
        .project-slider__slider .slider-project__nav:hover:after {
          color: #21315f; }
      .project-slider__slider .slider-project__nav:after {
        font-family: 'sigma' !important;
        font-size: 26px; }
      .project-slider__slider .slider-project__nav.swiper-button-next {
        right: 20px;
        padding-left: 6px; }
        .project-slider__slider .slider-project__nav.swiper-button-next:after {
          content: '\eaf8'; }
      .project-slider__slider .slider-project__nav.swiper-button-prev {
        left: 20px;
        padding-right: 6px; }
        .project-slider__slider .slider-project__nav.swiper-button-prev:after {
          content: '\eaf9'; }
      .project-slider__slider .slider-project__nav.swiper-button-disabled {
        background-color: rgba(255, 255, 255, 0.3) !important; }
        .project-slider__slider .slider-project__nav.swiper-button-disabled:after {
          opacity: 0.3 !important; }
    .project-slider__slider .slider-project__pagination {
      position: relative;
      width: 100%;
      max-width: 400px;
      position: relative;
      margin: 70px auto 0 auto;
      height: 2px; }
      @media only screen and (max-width: 640px) {
        .project-slider__slider .slider-project__pagination {
          max-width: 300px; } }
      .project-slider__slider .slider-project__pagination .swiper-pagination-progressbar-fill {
        background-color: #21315f; }

/*
 * Search Style
 * 
 */
.overlay-search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-y: auto;
  overflow-x: hidden;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out; }
  .overlay-search-open {
    visibility: visible;
    opacity: 1; }
    .overlay-search-open .overlay-search__close {
      opacity: 1; }
    .overlay-search-open .overlay-search__container {
      opacity: 1;
      transform: translateY(0); }
  .overlay-search__container {
    width: 100%;
    max-width: 960px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.3s ease-in-out 0.4s, transform 0.3s ease-in-out 0.4s; }
  .overlay-search__form {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 768px) {
      .overlay-search__form {
        padding: 30px 0; } }
    .overlay-search__form .form-requirement {
      color: #ff8300;
      padding: 10px 0 0 0;
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 20.8px;
      line-height: 1.3rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      opacity: 1; }
    .overlay-search__form .form-input {
      width: 100%; }
      .overlay-search__form .form-input.-parent-invalid .form-requirement {
        width: 100%;
        display: block; }
    .overlay-search__form__input {
      width: 100%;
      height: 60px;
      margin: 0;
      padding: 0 15px 0 0;
      display: block;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #ff8300;
      border-radius: 0;
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 64px;
      line-height: 4rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      color: #21315f; }
      .overlay-search__form__input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px transparent inset;
        -webkit-text-fill-color: #21315f;
        caret-color: #21315f; }
      @media only screen and (max-width: 1248px) {
        .overlay-search__form__input {
          font-size: 45px;
          font-size: 2.8125rem;
          line-height: 72px;
          line-height: 4.5rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 1024px) {
        .overlay-search__form__input {
          height: 50px;
          font-size: 35px;
          font-size: 2.1875rem;
          line-height: 56px;
          line-height: 3.5rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 640px) {
        .overlay-search__form__input {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 48px;
          line-height: 3rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .overlay-search__form__input::placeholder {
        color: #6c6c6c; }
    .overlay-search__form__button {
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: 1px solid #ff8300;
      outline: none;
      appearance: none;
      cursor: pointer; }
      @media only screen and (max-width: 1024px) {
        .overlay-search__form__button {
          width: 50px;
          height: 50px; } }
      .overlay-search__form__button .icon-sigma-search {
        font-size: 24px;
        color: #ff8300; }
  .overlay-search__icon .icon-sigma-search {
    color: #ff8300;
    font-size: 40px; }
    @media only screen and (max-width: 1024px) {
      .overlay-search__icon .icon-sigma-search {
        font-size: 30px; } }
    @media only screen and (max-width: 640px) {
      .overlay-search__icon .icon-sigma-search {
        font-size: 25px; } }

.news-container {
  width: 100%;
  overflow: hidden;
  padding: 70px 0;
  background-color: #f3f2ed; }
  .news-container__title {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 1024px) {
      .news-container__title {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; } }
    .news-container__title .news-title {
      color: #21315f;
      font-weight: 700;
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 36px;
      line-height: 2.25rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      @media only screen and (max-width: 1024px) {
        .news-container__title .news-title {
          margin-bottom: 10px; } }
    .news-container__title .news-button {
      padding-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: #6c6c6c;
      font-size: 25px;
      font-size: 1.5625rem;
      line-height: 40px;
      line-height: 2.5rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 700;
      cursor: pointer;
      transition: color 0.2s ease-in-out; }
      @media only screen and (max-width: 1024px) {
        .news-container__title .news-button {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          line-height: 2rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .news-container__title .news-button:hover {
        color: #ff8300; }
        .news-container__title .news-button:hover span {
          color: #ff8300; }
      .news-container__title .news-button span {
        margin-left: 15px;
        display: inline-block;
        font-size: 17px;
        color: #6c6c6c;
        transition: color 0.2s ease-in-out; }
        @media only screen and (max-width: 1024px) {
          .news-container__title .news-button span {
            margin-left: 12px;
            font-size: 14px; } }
  .news-container__content {
    position: relative;
    width: 100%; }
  .news-container__item {
    width: 100%;
    padding: 35px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dbdbdb; }
    @media only screen and (max-width: 768px) {
      .news-container__item {
        padding: 25px 0;
        flex-direction: column;
        align-items: flex-start; } }
    .news-container__item:first-child {
      border-top: 1px solid #dbdbdb; }
    .news-container__item:hover .news-image {
      opacity: 1;
      visibility: visible; }
    .news-container__item:hover .news-detail-text {
      color: #ff8300; }
    .news-container__item:hover .news-detail-icon {
      background-color: #ff8300;
      color: #ffffff;
      border: 1px solid #ff8300; }
    .news-container__item .news-image {
      position: absolute;
      top: 0;
      right: 230px;
      width: 350px;
      min-height: 350px;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out; }
      @media only screen and (max-width: 1024px) {
        .news-container__item .news-image {
          display: none; } }
      .news-container__item .news-image img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .news-container__item .news-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-right: 15px; }
      @media only screen and (max-width: 768px) {
        .news-container__item .news-content {
          padding-right: 0;
          margin-bottom: 25px; } }
      .news-container__item .news-content-title {
        color: #21315f;
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        font-weight: 500; }
      .news-container__item .news-content-date {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 19.2px;
        line-height: 1.2rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        font-weight: 400; }
    .news-container__item .news-detail {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      cursor: pointer; }
      .news-container__item .news-detail-text {
        padding-right: 15px;
        color: #6c6c6c;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 22.4px;
        line-height: 1.4rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        font-weight: 400;
        transition: color 0.2s ease-in-out; }
      .news-container__item .news-detail-icon {
        width: 40px;
        height: 40px;
        color: #6c6c6c;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #6c6c6c;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out; }

/*
 * Privacy Policy Style
 * 
 */
.privacy-policy {
  position: fixed;
  left: 50%;
  bottom: 20px;
  z-index: 5;
  width: 100%;
  max-width: 800px;
  min-height: 100px;
  padding: 30px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(100%);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: rgba(0, 0, 0, 0.8); }
  @media only screen and (max-width: 1024px) {
    .privacy-policy {
      max-width: 1100px;
      bottom: 0;
      border-radius: 0;
      padding: 30px 30px 20px 30px; } }
  .privacy-policy.-show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0); }
  .privacy-policy__description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22.4px;
    line-height: 1.4rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    color: #ffffff; }
    .privacy-policy__description a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 22.4px;
      line-height: 1.4rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      color: #ffffff;
      text-decoration: underline; }
  .privacy-policy__close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer; }

/*
 * Form Style
 * 
 */
.form-requirement {
  display: none; }

/*
 * Content Default Style
 * 
 */
.content-style {
  font-family: "Nexa", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 25.6px;
  line-height: 1.6rem;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  font-weight: 400;
  color: #6c6c6c; }
  @media only screen and (max-width: 1024px) {
    .content-style {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 22.4px;
      line-height: 1.4rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; } }
  .content-style h1,
  .content-style h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 36px;
    line-height: 2.25rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal; }
    @media only screen and (max-width: 1024px) {
      .content-style h1,
      .content-style h2 {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 30px;
        line-height: 1.875rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
  .content-style h3,
  .content-style h4 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 30px;
    line-height: 1.875rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal; }
    @media only screen and (max-width: 1024px) {
      .content-style h3,
      .content-style h4 {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 26px;
        line-height: 1.625rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
  .content-style h5,
  .content-style h6 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 26px;
    line-height: 1.625rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal; }
    @media only screen and (max-width: 1024px) {
      .content-style h5,
      .content-style h6 {
        font-size: 16px;
        font-size: 1rem;
        line-height: 22px;
        line-height: 1.375rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; } }
  .content-style p {
    margin: 0 0 15px 0; }
  .content-style a {
    font-weight: 400;
    color: #ff8300;
    text-decoration: underline; }
  .content-style b,
  .content-style strong {
    font-weight: 500; }
  .content-style ol {
    margin: 0;
    padding: 5px 12px;
    list-style-type: none;
    list-style-position: inside; }
    .content-style ol li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 400;
      margin-bottom: 10px;
      color: #6c6c6c; }
      .content-style ol li:before {
        content: '·';
        display: inline-block;
        padding-top: 2px;
        margin-right: 10px;
        font-size: 60px; }
  .content-style ul {
    margin: 0;
    padding: 5px 30px;
    list-style-type: decimal; }
    .content-style ul ul {
      padding: 10px 30px; }
    .content-style ul em,
    .content-style ul i {
      font-style: italic; }
    .content-style ul li {
      font-weight: 400;
      margin-bottom: 10px;
      color: #6c6c6c; }
      .content-style ul li ul {
        padding: 0 30px; }

/*
 * Error Style
 * 
 */
.error {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  .error:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #21315f;
    opacity: 0.5; }
  .error__pattern {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8; }
  .error__logo {
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0; }
    @media only screen and (max-width: 768px) {
      .error__logo {
        padding: 30px 20px; } }
    .error__logo svg {
      max-width: 250px;
      display: block;
      fill: #ffffff; }
      @media only screen and (max-width: 768px) {
        .error__logo svg {
          max-width: 200px; } }
  .error__content {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 800px;
    height: 100%;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .error__content__title {
      color: #ffffff;
      text-align: center;
      font-size: 90px;
      font-size: 5.625rem;
      line-height: 100px;
      line-height: 6.25rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 300; }
      @media only screen and (max-width: 768px) {
        .error__content__title {
          font-size: 70px;
          font-size: 4.375rem;
          line-height: 80px;
          line-height: 5rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 560px) {
        .error__content__title {
          font-size: 55px;
          font-size: 3.4375rem;
          line-height: 65px;
          line-height: 4.0625rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .error__content__title strong {
        font-weight: 700; }
    .error__content__description {
      color: #ffffff;
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 32px;
      line-height: 2rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      margin: -10px 0 40px 0; }
      @media only screen and (max-width: 768px) {
        .error__content__description {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 28.8px;
          line-height: 1.8rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      @media only screen and (max-width: 560px) {
        .error__content__description {
          margin: 0 0 40px 0;
          font-size: 16px;
          font-size: 1rem;
          line-height: 25.6px;
          line-height: 1.6rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
    .error__content__button {
      color: #ffffff;
      font-size: 16px;
      font-size: 1rem;
      line-height: 25.6px;
      line-height: 1.6rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 600;
      padding: 13px 40px 10px 40px;
      border: 2px solid #ffffff;
      transition: color 0.2s linear, background-color 0.2s linear; }
      @media only screen and (max-width: 768px) {
        .error__content__button {
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 22.4px;
          line-height: 1.4rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          padding: 13px 30px 10px 30px; } }
      .error__content__button:hover {
        color: #21315f;
        background-color: #ffffff; }

/*
 * Pagination Style
 * 
 */
.pager {
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .pager__item {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Nexa", sans-serif;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 16px;
    line-height: 1rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 600; }
    @media only screen and (max-width: 560px) {
      .pager__item {
        display: none; } }
    @media only screen and (max-width: 560px) {
      .pager__item.-active {
        display: flex; } }
    @media only screen and (max-width: 560px) {
      .pager__item.-active + .pager__item {
        display: flex; } }
    .pager__item.-active .pager__link {
      background-color: #21315f;
      color: #ffffff; }
    .pager__item.-next, .pager__item.-prev {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 16px;
      line-height: 1rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      @media only screen and (max-width: 560px) {
        .pager__item.-next, .pager__item.-prev {
          display: flex; } }
  .pager__link {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 3px;
    font-family: "Nexa", sans-serif;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 16px;
    line-height: 1rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 600;
    color: #6c6c6c;
    text-decoration: none;
    transition: 0.3s; }
    .pager__link:hover, .pager__link:focus, .pager__link:active {
      background-color: #21315f;
      color: #ffffff; }
    .pager__link .pager__item.active + .pager__item .pager__link,
    .pager__link .pager__item:hover + .pager__item .pager__link {
      border-left-color: #21315f; }

.page {
  width: 100%;
  padding: 80px 0; }
  @media only screen and (max-width: 1024px) {
    .page {
      padding: 60px 0; } }
  .page .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 1024px) {
      .page .container {
        flex-direction: column;
        justify-content: flex-start; } }
  .page__title {
    font-weight: 700;
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 55px;
    line-height: 3.4375rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    color: #21315f;
    margin-bottom: 30px; }
  .page__select {
    width: 100%;
    height: 50px;
    padding: 3px 30px 0 15px;
    margin-bottom: 50px;
    display: none;
    background-color: #fff;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAGCAYAAAAVMmT4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTc1MDg3Qjg4QUIzMTFFOUJGOEFEQ0UzMEFEQTQwMkYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTc1MDg3Qjk4QUIzMTFFOUJGOEFEQ0UzMEFEQTQwMkYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NzUwODdCNjhBQjMxMUU5QkY4QURDRTMwQURBNDAyRiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NzUwODdCNzhBQjMxMUU5QkY4QURDRTMwQURBNDAyRiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PneA/n4AAAA6SURBVHjaYmBgYHgAxAYM+IEBVB2cYUBAIVw+AYg/YNFgABVPQDcBXQNOhegaEggpRNbwH5tCgAADAA5xD2C8R++MAAAAAElFTkSuQmCC");
    background-position: bottom 20px right 15px;
    background-repeat: no-repeat;
    appearance: none;
    border-radius: 50px;
    font-family: "Nexa", sans-serif;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22.4px;
    line-height: 1.4rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-weight: 500;
    outline: 0;
    border: 1px solid #dbdbdb; }
    @media only screen and (max-width: 1024px) {
      .page__select {
        display: block; } }
  .page__nav {
    width: 100%;
    max-width: 250px;
    position: sticky;
    top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0; }
    @media only screen and (max-width: 1024px) {
      .page__nav {
        display: none; } }
    .page__nav__link {
      margin-bottom: 20px;
      color: #6c6c6c;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      transition: color 0.2s ease-in-out; }
      .page__nav__link:hover {
        color: #ff8300; }
      .page__nav__link.-active {
        color: #ff8300;
        font-weight: 600; }
  .page__container {
    padding-left: 100px; }
    @media only screen and (max-width: 1200px) {
      .page__container {
        padding-left: 50px; } }
    @media only screen and (max-width: 1024px) {
      .page__container {
        padding-left: 0; } }

.fancybox-button--close {
  background-color: #ff8300 !important;
  color: #ffffff !important; }

/*
 * keyframes Style
 * 
 */
@keyframes modal-animation {
  0% {
    transform: translateY(80px); }
  100% {
    transform: translateY(0); } }

.modal-career {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: none;
  align-items: center;
  justify-content: center; }
  .modal-career__checkbox {
    display: none; }
    .modal-career__checkbox:checked + .modal-career {
      display: flex; }
  .modal-career__backdrop {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: rgba(33, 49, 95, 0.4); }
  .modal-career__content {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 990px;
    animation: modal-animation 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
  .modal-career__scroll {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 30px;
    width: 100%;
    min-height: 300px;
    height: auto;
    max-height: 85vh;
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden; }
    @media only screen and (max-width: 560px) {
      .modal-career__scroll {
        padding: 30px 20px;
        max-height: 75vh; } }
  .modal-career__image {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 10px;
    z-index: 10;
    animation: zoom 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }
    .modal-career__image img {
      width: 100%;
      max-width: 800px;
      height: auto; }
  .modal-career__video {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 10px;
    z-index: 10;
    animation: zoom 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }
    .modal-career__video .video-player {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      height: auto; }
      .modal-career__video .video-player video {
        width: 100%;
        max-width: 700px; }
  .modal-career__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
    background-color: #ff8300;
    cursor: pointer; }
    .modal-career__close:after, .modal-career__close:before {
      content: '';
      position: absolute;
      top: 12.5px;
      right: 18px;
      height: 20px;
      width: 2px;
      background-color: #ffffff; }
    .modal-career__close:after {
      transform: rotate(45deg); }
    .modal-career__close:before {
      transform: rotate(-45deg); }
    .modal-career__close.outside {
      margin: 0 0 10px 0; }
  .modal-career__form {
    width: 100%; }
    .modal-career__form .container {
      max-width: 1300px;
      background-color: #ffffff;
      padding: 0 10px; }
    .modal-career__form__title {
      width: 100%;
      padding: 40px 0 50px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .modal-career__form__title .title {
        justify-content: center;
        align-items: center;
        text-align: center; }
    .modal-career__form__wrapper {
      max-width: 950px;
      margin: 0 auto;
      padding-bottom: 50px; }
    .modal-career__form__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      @media only screen and (max-width: 1024px) {
        .modal-career__form__row {
          flex-direction: column; } }
      .modal-career__form__row--button {
        justify-content: flex-start;
        padding: 30px 0 0 0; }
      .modal-career__form__row--checkbox {
        flex-direction: column;
        justify-content: flex-start; }
        .modal-career__form__row--checkbox .form-requirement {
          order: 2; }
      .modal-career__form__row.-parent-invalid .form-requirement {
        width: 100%;
        display: block; }
      .modal-career__form__row .form-requirement {
        padding-top: 10px;
        order: 4;
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 19.2px;
        line-height: 1.2rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        color: #de2c65; }
    .modal-career__form__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: calc(50% - 25px);
      margin-bottom: 30px;
      padding: 5px 0 0 0;
      background-color: #ffffff; }
      @media only screen and (max-width: 1024px) {
        .modal-career__form__item {
          width: 100%; } }
      .modal-career__form__item--full {
        width: 100%; }
      .modal-career__form__item.-parent-invalid:after {
        background-color: #de2c65; }
      .modal-career__form__item.-parent-invalid .form-requirement {
        width: 100%;
        display: block; }
      .modal-career__form__item:after {
        order: 3;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 10px;
        background-color: #eeeeee; }
        @media only screen and (max-width: 560px) {
          .modal-career__form__item:after {
            margin-top: 0; } }
      .modal-career__form__item--no-border:after {
        display: none;
        background-color: transparent; }
    .modal-career__form__label {
      order: 1;
      width: 100%;
      max-width: 130px;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      color: #21315f; }
      @media only screen and (max-width: 560px) {
        .modal-career__form__label {
          font-size: 13px;
          font-size: 0.8125rem;
          line-height: 20.8px;
          line-height: 1.3rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal;
          max-width: 120px; } }
      .modal-career__form__label--file {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%; }
        @media only screen and (max-width: 560px) {
          .modal-career__form__label--file {
            max-width: 100%;
            flex-direction: column;
            align-items: flex-start; } }
      .modal-career__form__label--checkbox {
        position: relative;
        max-width: 100%;
        padding: 2px 0 0 35px;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 20px;
        line-height: 1.25rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        font-weight: 400;
        color: #6c6c6c; }
        @media only screen and (max-width: 560px) {
          .modal-career__form__label--checkbox {
            max-width: 100%; } }
        .modal-career__form__label--checkbox a {
          color: #21315f;
          text-decoration: underline; }
        .modal-career__form__label--checkbox:after {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          width: 14px;
          height: 14px;
          border: 1px solid #6c6c6c; }
        .modal-career__form__label--checkbox:before {
          content: '';
          position: absolute;
          top: 6px;
          left: 4px;
          width: 6px;
          height: 6px;
          display: none;
          border: 1px solid #21315f;
          background-color: #21315f; }
    .modal-career__form__input, .modal-career__form__select, .modal-career__form__textarea {
      order: 2;
      width: 100%;
      border: 0;
      height: 30px;
      font-family: "Nexa", sans-serif;
      font-size: 16px;
      font-size: 1rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      color: #6c6c6c;
      appearance: none;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none; }
      .modal-career__form__input::placeholder, .modal-career__form__select::placeholder, .modal-career__form__textarea::placeholder {
        height: 30px;
        color: #c6c6c6;
        font-size: 16px;
        font-size: 1rem;
        line-height: 30px;
        line-height: 1.875rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; }
        @media only screen and (max-width: 560px) {
          .modal-career__form__input::placeholder, .modal-career__form__select::placeholder, .modal-career__form__textarea::placeholder {
            font-size: 13px;
            font-size: 0.8125rem;
            line-height: 30px;
            line-height: 1.875rem;
            font-feature-settings: 'kern' 1;
            font-kerning: normal;
            margin-bottom: 2px; } }
    .modal-career__form__input {
      width: calc(100% - 130px); }
      @media only screen and (max-width: 560px) {
        .modal-career__form__input {
          width: calc(100% - 120px); } }
    .modal-career__form__select {
      width: calc(100% - 130px); }
      @media only screen and (max-width: 560px) {
        .modal-career__form__select {
          width: calc(100% - 120px); } }
      .modal-career__form__select:invalid {
        height: 30px;
        color: #c6c6c6;
        font-size: 16px;
        font-size: 1rem;
        line-height: 30px;
        line-height: 1.875rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; }
    .modal-career__form__textarea {
      width: 100%;
      resize: none;
      height: 100px;
      color: #6c6c6c;
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      @media only screen and (max-width: 560px) {
        .modal-career__form__textarea {
          height: 80px;
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 22px;
          line-height: 1.375rem;
          font-feature-settings: 'kern' 1;
          font-kerning: normal; } }
      .modal-career__form__textarea::placeholder {
        height: 30px;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 30px;
        line-height: 1.875rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; }
        @media only screen and (max-width: 560px) {
          .modal-career__form__textarea::placeholder {
            font-size: 14px;
            font-size: 0.875rem;
            line-height: 22px;
            line-height: 1.375rem;
            font-feature-settings: 'kern' 1;
            font-kerning: normal; } }
    .modal-career__form__checkbox {
      display: none; }
      .modal-career__form__checkbox.-invalid + .modal-career__form__label--checkbox::after {
        border-color: #de2c65; }
      .modal-career__form__checkbox:checked + .modal-career__form__label--checkbox::before {
        display: block; }
    .modal-career__form__file {
      display: none; }
      .modal-career__form__file__wrapper {
        width: 100%;
        padding: 18px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        order: 2; }
        .modal-career__form__file__wrapper.-parent-invalid .form-requirement {
          width: 100%;
          display: block; }
      .modal-career__form__file__choose {
        width: 200px;
        padding: 10px;
        margin-right: 15px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        background-color: #ebebeb;
        font-weight: 400;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 22.4px;
        line-height: 1.4rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        color: #6c6c6c;
        cursor: pointer; }
        @media only screen and (max-width: 560px) {
          .modal-career__form__file__choose {
            margin-bottom: 10px; } }
      .modal-career__form__file__name {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        font-weight: 400;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 22.4px;
        line-height: 1.4rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal;
        color: #6c6c6c; }
    .modal-career__form__button {
      width: 100%;
      padding: 20px 15px 15px 15px;
      background-color: #ffffff;
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 28.8px;
      line-height: 1.8rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal;
      font-weight: 500;
      border: 1px solid #21315f;
      appearance: none;
      outline: none;
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; }
      .modal-career__form__button:hover {
        background-color: #21315f;
        color: #ffffff; }
  .modal-career .form-requirement {
    display: none;
    color: #de2c65;
    padding: 5px 0 0 0;
    font-weight: 600;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 19.2px;
    line-height: 1.2rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal; }
  .modal-career .notification {
    width: 100%; }
    .modal-career .notification__message {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      color: #4bb543;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 22px;
      line-height: 1.375rem;
      font-feature-settings: 'kern' 1;
      font-kerning: normal; }
      .modal-career .notification__message.-error {
        color: #de2c65; }
        .modal-career .notification__message.-error span {
          margin-top: -2px;
          color: #de2c65; }
      .modal-career .notification__message span {
        margin-right: 10px;
        display: inline-block;
        color: #4bb543;
        font-size: 17px;
        font-size: 1.0625rem;
        line-height: 22px;
        line-height: 1.375rem;
        font-feature-settings: 'kern' 1;
        font-kerning: normal; }

@keyframes modal-animation {
  0% {
    transform: translateY(80px); }
  100% {
    transform: translateY(0); } }
